<template>
  <v-app>
    <home-app-bar v-if="!hideMenu" />

    <home-view />

    <!-- <home-footer /> -->
  </v-app>
</template>

<script>
export default {
  name: "HomeLayout",
  components: {
    HomeAppBar: () => import("@/layouts/home/AppBar"),
    // HomeFooter: () => import('@/layouts/home/Footer'),
    HomeView: () => import("@/layouts/home/View")
  },
  data: () => ({
    hideMenu: false
  }),
  methods: {
    init() {
      this.hideMenu = this.$route.path === "/help";
    }
  },
  mounted() {
    window.$i = this;
    this.init();
  }
};
</script>
